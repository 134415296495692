<template>
    <v-container>
        <item v-if="board" v-bind="{ board }" opened />
    </v-container>
</template>

<script>
import Item from "./item.vue";

export default {
    components: {
        Item,
    },
    props: {
        board: { type: Object, default: null },
        me: { type: Object, default: null },
    },
    computed: {
        isBookmark() {
            return this.me ? this.me.bookmarks.find((bookmark) => bookmark._board == this.board._id) : false;
        },
        isLike() {
            return this.me ? this.me.likes.find((like) => like._board == this.board._id) : false;
        },
    },
};
</script>
