var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_vm.board ? _c('item', _vm._b({
    attrs: {
      "opened": ""
    }
  }, 'item', {
    board: _vm.board
  }, false)) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }